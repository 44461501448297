import React from "react"
import SEO from "../../components/seo"
import Header from "../../components/header"

import WorkFooter from "../../components/portfolioItems/worksFooter"


import Herospace from "../../../assets/images/portfolio_items/saut/saut.png"
import saut1 from "../../../assets/images/portfolio_items/saut/saut1.png"
import saut2 from "../../../assets/images/portfolio_items/saut/saut2.png"
import saut3 from "../../../assets/images/portfolio_items/saut/saut3.png"
import saut4 from "../../../assets/images/portfolio_items/saut/saut4.png"
import saut5 from "../../../assets/images/portfolio_items/saut/saut5.png"

export default () => 
<div>
    <SEO title={'ICON Worldwide | Saut Academy - Design Project'} 
    description="A unique design strategy for a special school, the Saut Academy: discover more on ICON's portfolio! "
    canonical={'https://icon-worldwide.com/works/saut'}/>
        <Header bgcolor="#292929"/>
    <div id="portfolio-item">
        <div className="herospace">
            <img src={Herospace} alt="Saut Academy, ICON Worldwide, design strategy, portfolio" title=" ICON Worldwide portfolio, design strategy, Saut Academy"/>
            <div className="title"><h1>Saut Academy<span></span></h1>
            <h2>A unique design strategy for a special school</h2>
            </div>
        </div>
        <div className="portfolio-inner">
        <p>The Saudia Arabia based Saut academy is a special school for children with down syndrome. The website is a tremendous resource for teachers, doctors and parents. Our design celebrates the children and serves as support for all print and social media campaigns.</p>
        <div className="portfolio-images">
            <img src={saut1} alt="Saut Academy, ICON Worldwide, design strategy, portfolio" title=" ICON Worldwide portfolio, design strategy, Saut Academy"/>
            <img src={saut2} alt="Saut Academy, ICON Worldwide, design strategy, portfolio" title=" ICON Worldwide portfolio, design strategy, Saut Academy"/>
            <img src={saut4} alt="Saut Academy, ICON Worldwide, design strategy, portfolio" title=" ICON Worldwide portfolio, design strategy, Saut Academy"/>
            <img src={saut3} alt="Saut Academy, ICON Worldwide, design strategy, portfolio" title=" ICON Worldwide portfolio, design strategy, Saut Academy"/>
            <img src={saut5} alt="Saut Academy, ICON Worldwide, design strategy, portfolio" title=" ICON Worldwide portfolio, design strategy, Saut Academy"/>
        </div>
        <WorkFooter previous="vifor-pharma" next="swiss-finance-institute"/>
        </div>
    </div>
</div>